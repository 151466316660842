var toastr = window.toastr || {};
var stopwatchDocumentClick = function (event) {
	// console.log("click", event);
	let stopwatch = $(event.target).closest("#stopwatch");
	if (stopwatch.length > 0) return;
	let scope = $("#stopwatch > div").scope();
	scope.$ctrl.timer.add_new = false;
	scope.$ctrl.popupToggle();
};
class StopwatchCtrl {
	constructor($scope, element, $http, $window, $timeout) {
		let ctrl = this;
		this.http = $http;
		this.timer = {is_internal_hours: false};
		this.timer = {add_new: false};
		this.window = $window;
		this.timeout = $timeout;
		this.close = $scope.$parent.$ctrl.popupToggle.bind($scope.$parent.$ctrl);
		Object.defineProperty(ctrl, 'tasks', {
			get() {
				return $scope.$parent.$ctrl.tasks;
			}, set(tasks) {
				$scope.$parent.$ctrl.tasks = tasks;
			}
		});
	}

	getQueryParam(param, paramValue) {
		return "&" + param + "=" + paramValue;
	}

	stopTaskSave(task) {
		let callback = function () {
			this.close();
			let url = '/timesheet?create_from=timer';
				url += this.getQueryParam("is_internal_hours", task.is_internal_hours);
			if (task.internal_project) {
				url += this.getQueryParam("internal_project_id", task.internal_project_id);
				url += this.getQueryParam("internal_project_name", task.internal_project.project_name);
			}
			if (task.project) {
				url += this.getQueryParam("project_id", task.project_id);
				url += this.getQueryParam("project_name", (task.project.customer.customer_name + ' - ' + task.project.project_name));
				url += this.getQueryParam("customer_id", task.project.customer_id);
				url += this.getQueryParam("customer_name", task.project.customer.customer_name);
			}
			if (task.reference) {
				url += this.getQueryParam("reference_id", task.reference_id);
				url += this.getQueryParam("reference_name", task.reference.project_name);
			}
			if (task.practice_name) {
				url += this.getQueryParam("practice_id", task.practice_id);
				url += this.getQueryParam("practice_name", task.practice_name);
			}
			url += this.getQueryParam("start_date", task.start_datetime);
			url += this.getQueryParam("input_effort_min", task.elapsed_minutes);
			url += this.getQueryParam("user_id", task.created_by);
			// console.log("durata salvata", task.elapsed_minutes);
			this.window.location.href = window.origin + url;
		};
		// callback.call(this);
		this.stopTaskDelete(task, callback.bind(this));
		lscache.set('stopwatch_items', this.tasks, 1);
	}

	stopTaskDelete(taskToDelete, callback) {
		let ctrl = this;
		this.http.delete('/stopwatch/' + taskToDelete.stopwatch_id).then(function (response) {
			if (response && response.data) {
				ctrl.timeout(function () {
					ctrl.tasks.forEach((task, i) => {
						if (task.stopwatch_id === taskToDelete.stopwatch_id) {
							ctrl.tasks.splice(i, 1);
							lscache.set('stopwatch_items', ctrl.tasks, 1);
							return false;
						}
					});
					if (callback) callback();
				});
			}
		});

	}

	popupToggle() {
		let element = angular.element(this.element).find("#stopwatch-dropdown");
		let visible = element.css("display") !== "none";
		element.toggle();
		if (visible) document.body.addEventListener("click", stopwatchDocumentClick);
		else document.body.removeEventListener("click", stopwatchDocumentClick);
	}

	addTask($ctrl) {
		if (!this.timer.project_id && !this.timer.internal_project_id) {
			toastr.warning("No project selected");
			return;
		}

		if (this.timer.project_id && this.timer.reference_id === null && !this.timer.internal_project_id) {
			toastr.warning("No reference selected");
			return;
		}

		// if (this.is_internal_hours && !this.timer.reference_id) {
		// 	toastr.warning("No activity selected");
		// 	return;
		// }
		let ctrl = this;
		this.http.post('/stopwatch', this.timer).then(function (response) {
			if (response && response.status === 200)
				ctrl.tasks = response.data;
			$ctrl.timer.add_new = false;
			ctrl.close();
			lscache.set('stopwatch_items', ctrl.tasks, 1);
		});

	}
}

var module = angular.module('kv');
angular.module('kv').component('stopwatch',
	{
		templateUrl: '/assets/custom/js/directives/stopwatch/stopwatch.html',
		controller: StopwatchCtrl
	});

StopwatchCtrl.$inject = ["$scope", "$element", "$http", "$window", "$timeout"];

