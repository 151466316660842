kv.controller('legalCaseViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'legal_case_id';
	vm.entity = 'legal_case';
	vm.entityType = 'legal_case';
	vm.entityId = vm.bladeParams.legalCaseId;
	vm.hasErrorWhileCallingBufferShowMessage = false;
	vm.current_date = new Date();

	let copyData = {};

	vm.thecurdate = moment().format('YYYY-MM-DD');
	vm.legalCaseProject = JSON.parse(vm.bladeParams.legalCaseProject);

	let url_segments = window.location.pathname.split('/');
	let page_entity_id = url_segments[2];
	let page_identifier = url_segments[3];

	toastr.options = {
		"closeButton": true,
		"debug": false,
		"positionClass": "toast-top-center",
		"onclick": null,
		"showDuration": "500",
		"hideDuration": "500",
		"timeOut": "5000",
		"extendedTimeOut": "1000",
		"showEasing": "swing",
		"hideEasing": "linear",
		"showMethod": "fadeIn",
		"hideMethod": "fadeOut"
	};

	vm.commentsConfig = {
		entity_type: vm.entity
	};

	vm.goToProject = function(){
		if(vm.legalCaseProject[0].reference_id){
			window.open("/project/" + vm.legalCaseProject[0].project_id + "#k_tabs_references", '_blank');
		}
		window.open("/project/" + vm.legalCaseProject[0].project_id, '_blank');
	};

	vm.getBillingRules = function(){
		let project_id = null;
		if(vm.legalCaseProject[0].reference_id){
			project_id = vm.legalCaseProject[0].reference_id;
		} else {
			project_id = vm.legalCaseProject[0].project_id;
		}
		
		vm.currency = vm.legalCaseProject[0].project.currency;

		let REST = resource.init('billing_rule/list');
		REST.getArray({
			params: {
				project_id: project_id
			}
		}).then(function (data) {
			vm.billing_rule = data;
		});
	}

	if(vm.legalCaseProject.length){
		vm.getBillingRules();
	}

	vm.getQueryParam = function (param, param_value) {
		return "&" + param + "=" + param_value;
	};

	vm.addLegalCaseToTimesheet = function(entity){
		console.log("entity", entity);
		if(entity.legal_case_project.length > 0) {
			let url = 'timesheet?create_from=legal_case&user_id=' + vm.API.user.user_id;
			if (entity.customer) {
				url += vm.getQueryParam("customer_id", entity.customer.customer_id);
				url += vm.getQueryParam("customer_name", entity.customer.customer_name);
			}

			if (entity.legal_case_project && entity.legal_case_project[0].project) {
				url += vm.getQueryParam("project_id", entity.legal_case_project[0].project.project_id);
				url += vm.getQueryParam("project_name", entity.legal_case_project[0].project.project_name);
			}
			if (entity.legal_case_project && entity.legal_case_project[0].reference) {
				url += vm.getQueryParam("reference_id", entity.legal_case_project[0].reference.project_id);
				url += vm.getQueryParam("reference_name", entity.legal_case_project[0].reference.project_name);
			}

			if (entity.open_date) {
				let currentDate = new Date();
				let day = currentDate.getDate();
				let month = currentDate.getMonth() + 1;
				let year = currentDate.getFullYear();
				entity.start_date = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day) + ' ' + vm.API.START_DAY_AT;

				url += vm.getQueryParam("start_date", entity.start_date);
			}
			
			window.location.href = vm.API.URL + url;
		}else{
			toastr.error(vm.trans('NO_PROJECT_OR_REFERENCE_ASSOCIATED'));
		}
	};

	vm.valueFormatByType = function (rule_type, currency) {
		switch (rule_type) {
			case "FEE_ADVANCE":
			case "FLAT_FEE":
			case "SUCCESS_FEE":
			case "CAP":
			case "HEARING":
			case "ESTIMATE":
				return (currency ? currency : '-');
				break;
			case "DISCOUNT":
				return " %";
			case "FEE_DISCOUNT":
				return " %";
			case "SUBSCRIPTION":
				return (currency ? currency + "/" + vm.trans("MONTH").toLowerCase() : '-');
				break;
			case "HOURLY_BLENDED":
				return (currency ? currency + "/h" : '-');
				break;

			case "PRACTICE_ALLOC":
			case "USER_ALLOC":
			case "PERCENT_ALLOC":
			case "HOURLY_SENIORITY":
			case "HOURLY_USER":
			case "HOURLY_ACTIVITY":
			case "HOURLY_THRESHOLD":

				break;
		}
	}

	vm.changeStatus = function (case_status_id) {
		let active = 1;
		if(case_status_id == 4 || case_status_id == 5) {
			active = 0;
		}

		var REST = resource.init("legal_case");
		REST.update({
			id: page_entity_id,
			data: {
				status_id: case_status_id,
				is_active: active
			},
			params: {
				force_update_entity: true
			}
		}).then(function (data) {
			window.location = "/legal_case/" + data.data.legal_case_id;
		});
	};

	vm.edit = function () {
		$state.go('app.' + vm.entity + '.edit', {id: vm.result[vm.primaryKey], result: vm.result});
	};

	vm.getCourtPhases = function () {
		var REST = resource.init('court_phase/list');
		REST.getArray({
			params: {
				legal_case_id: page_entity_id
			}
		}).then(function (data) {
			vm.court_phases = data;
		});
	};

	vm.getCourtPhases();

	vm.getCourtHearings = function () {
		var REST = resource.init('court_hearing/list');
		REST.get({
			params: {
				legal_case_id: page_entity_id
			}
		}).then(function (data) {
			vm.court_hearings = data.data;
		});
	};

	vm.deleteCourtPhase = function ($index) {
		this.openModal({
			animation: true,
			component: 'modalConfirm',
			resolve: {
				title: function () {
					return 'DELETE';
				},
				content: function () {
					return $filter('trans')('LANG.ARE_YOU_SURE_DELETE');
				}
			}
		}).then(() => {
			let rest = this.getInjection('resource').init("court_phase");
			let ctrl = this;
			rest.delete({
				params: {
					id: vm.court_phases[$index].court_phase_id
				},
				success: function () {
					vm.court_phases.splice($index, 1);
					vm.getCourtPhases();
				}
			});
		});
	};


	vm.deleteLegalCase = function ($id) {
		vm.openModal({
			component: 'modalConfirm',
			resolve: {
				content: () => {
					return $filter("trans")("ARE_YOU_SURE_DELETE");
				},
				title: () => {
					return $filter("trans")("DELETE");
				}
			}
		}).then(function () {
			vm.rest = resource.init("legal_case");

			vm.rest.delete({
				params: {
					id: $id
				}
			}).then(function (response) {
				if (response.status) {
					toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
					window.location = "/legal_case";
				} else {
					if(response.error.error_code == 23000){
						toastr.error(response.error.message);
					}
					else{
						toastr.error(response.error.status_id);
					}

				}
			});
		});
	};


	vm.syncCourtHearing = function () {
		toastr.info(vm.trans("SYNCHING"), "", {
			"closeButton": true,
			"debug": false,
			"positionClass": "toast-top-center",
			"onclick": null,
			"showDuration": "1000",
			"hideDuration": "100",
			"timeOut": "2000",
			"extendedTimeOut": "1000",
			"showEasing": "swing",
			"hideEasing": "linear",
			"showMethod": "fadeIn",
			"hideMethod": "fadeOut"
		});

		vm.syncingCourtHearing = true;
		var REST = resource.init('court_hearing/sync');
		REST.get({
			params: {
				legal_case_id: page_entity_id,
				fresh: new Date()
			}
		}).then(function (result) {
			vm.sync_result = result;
			if(result.data){
				vm.sync_result = result.data;
			}
			vm.getCourtPhases();
			vm.syncingCourtHearing = false;
			// show sync result
			if (vm.sync_result.status !== 'success') {
				toastr.error(vm.sync_result.status_details);
			} else {
				toastr.success(vm.sync_result.status_details);
				location.reload();
			}
		});
	};
		
	vm.editCourtPhase = function (entity) {
		let isNewRecord = !entity;
		vm.openModal({
			templateUrl: "court_phase-edit",
			controller: 'courtPhaseEditCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				params: function () {
					if (!entity) {
						entity = {
							legal_case_id: page_entity_id
						};
					}
					return entity;
				},
				saveCallback: function () {
					return function(response){
						vm.getCourtPhases();
						vm.syncCourtHearing();
						response.modal.close();
					};
				}
			}
		});
	};

	vm.buildList = function (data) {
		vm.projectTaskList = data;
		copyData = data;
		if (data) {
			$scope.count = {
				ongoing: 0,
				realized: 0
			};

			angular.forEach(vm.projectTaskList, function (v, i) {
				if (v.done !== true) {
					$scope.count.ongoing = parseInt($scope.count.ongoing) + 1;
				} else {
					$scope.count.realized = parseInt($scope.count.realized) + 1;
				}
			});
		}
	};

	vm.getTaskList = function () {
		if(page_entity_id) {
			let taskListRest = resource.init('legal_case/' + page_entity_id);
			taskListRest.getArray({
				params: {
					action: 'taskList'
				}
			}).then(function (response) {
				vm.projectTaskList = response;
				vm.projectTaskListCount = null;
				$.each(response, function (index, value) {
					if (value.done !== true) {
						vm.projectTaskListCount++;
					}
					vm.projectTaskList[index].responsible_count = value.users.length;
				});
				vm.buildList(vm.projectTaskList);
			});
		}
	};

	vm.getTaskList();

	/**
	 * add task
	 * @param {[type]} ev     [description]
	 * @param {[type]} $index [description]
	 */

	vm.addEditTask = function (entity) {
		let customer = JSON.parse(vm.bladeParams.legalCaseCustomer);
		// console.log(customer)
		vm.openModal({
			templateUrl: "task-edit",
			size: 'lg',
			controller: "taskEditCtrl",
			controllerAs: "vm",
			resolve: {
				params: function () {
					return {
						entity: entity,
						task_id: entity ? entity.task_id : null,
						legal_case: {
							legal_case_id: vm.bladeParams.legalCaseId,
							name: vm.bladeParams.legalCaseName,
							number: vm.bladeParams.legalCaseNumber,
							customer_id: customer.customer_id,
							customer_name: customer.customer_name
						}
					};
				}
			}
		}).then(response => {
			if (!response) return;
			vm.getTaskList();
		});
	};

	vm.deleteTask = function deleteEntity($index) {
		this.openModal({
			animation: true,
			component: 'modalConfirm',
			resolve: {
				title: function () {
					return 'DELETE';
				},
				content: function () {
					return $filter('trans')('LANG.ARE_YOU_SURE_DELETE');
				}
			}
		}).then(() => {
			let rest = this.getInjection('resource').init("task");
			let ctrl = this;
			rest.delete({
				params: {
					id: vm.projectTaskList[$index].task_id
				},
				success: function () {
					vm.projectTaskList.splice($index, 1);
					vm.getTaskList();
				}
			});
		});
	};


	/**
	 * change task status
	 * @param  {[type]} $activity_id [description]
	 * @return {[type]}              [description]
	 */
	vm.changeStatusTask = function (entity) {
		var REST_task = resource.init("task");
		entity.done = !entity.done;
		REST_task.update({
			id: entity.task_id,
			params: {
				action: (entity.done ? 'markCompleted' : 'markToDo')
			}
		}).then(function () {
			vm.getTaskList();
		});
	}

	/**
	 * filter task list by selected status
	 * @param  {[type]} status [description]
	 * @return {[type]}        [description]
	 */
	vm.filterList = function (status) {
		if (status) {
			vm.projectTaskList = [];
			angular.forEach(copyData, function (v, i) {
				if (v.done == false && status == 'ongoing') {
					vm.projectTaskList = vm.projectTaskList.concat(v);
				}
				if (status == 'realized' && v.done == true) {
					vm.projectTaskList = vm.projectTaskList.concat(v);
				}
				if (status == 'all') {
					vm.projectTaskList = copyData;

				}
			});
		}
	};

	vm.editHearing = function (court_hearing_id){
		vm.openModal({
			templateUrl: "hearing-edit",
			size: 'lg',
			controller: "hearingEditCtrl",
			controllerAs: "vm",
			resolve: {
				params: function () {
					return {
						court_hearing_id: court_hearing_id ? court_hearing_id : null,
					};
				}
			},
			backdrop: 'static',
			keyboard: false
		}).then(response => {
			if (!response) return;
			vm.getCourtPhases();
		});
	}

	vm.calculateStampTax = function() {
		let modalInstance = $uibModal.open({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-body',
			templateUrl: 'stamp_tax_calculation',
			controller: function ($scope, $uibModalInstance, data, resource) {
				let vm = this;
				if(data) {
					vm.data = {
						estimated_value: data.estimated_value,
					}
				}

				$scope.$watch('vm.data.estimated_value', function (value) {
					vm.calculateStandardStampTax(value);
					vm.calculateAppealStampTax(value);
					vm.calculateRecourseStampTax(value);
				});

				vm.calculateStandardStampTax = function (value) {
					if(value){
						if(value <= 500){
							vm.data.stamp_tax = 0.08 * vm.data.estimated_value;
							if(vm.data.stamp_tax < 20){
								vm.data.stamp_tax = 20;
							}
						} else if (value > 500 && value <= 5000) {
							vm.data.stamp_tax = 40 + 0.07 * (vm.data.estimated_value - 500);
						} else if (value > 5000 && value <= 25000) {
							vm.data.stamp_tax = 355 + 0.05 * (vm.data.estimated_value - 5000);
						} else if (value > 25000 && value <= 50000) {
							vm.data.stamp_tax = 1355 + 0.03 * (vm.data.estimated_value - 25000);
						} else if (value > 50000 && value <= 250000) {
							vm.data.stamp_tax = 2105 + 0.02 * (vm.data.estimated_value - 50000);
						} else {
							vm.data.stamp_tax = 6105 + 0.01 * (vm.data.estimated_value - 250000);
						}
						vm.data.stamp_tax = parseFloat(vm.data.stamp_tax).toFixed(2);
					} else {
						vm.data.stamp_tax = 0;
					}
				}

				vm.calculateAppealStampTax = function (value) {
					if(value){
						vm.data.appeal_stamp_tax = vm.data.stamp_tax / 2;
						if(vm.data.appeal_stamp_tax < 20) {
							vm.data.appeal_stamp_tax = 20;
						}
						vm.data.appeal_stamp_tax = parseFloat(vm.data.appeal_stamp_tax).toFixed(2);
					} else {
						vm.data.appeal_stamp_tax = 0;
					}
				}

				vm.calculateRecourseStampTax = function (value) {
					if(value){
						vm.data.recourse_stamp_tax = vm.data.stamp_tax / 2;
						if(vm.data.recourse_stamp_tax < 100) {
							vm.data.recourse_stamp_tax = 100;
						}
						vm.data.recourse_stamp_tax = parseFloat(vm.data.recourse_stamp_tax).toFixed(2);
					} else {
						vm.data.recourse_stamp_tax = 0;
					}
				}

				vm.close = function () {
					$uibModalInstance.dismiss('close');
				}
			},
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				data: function () {
					return {
						estimated_value: vm.bladeParams.estimatedValue ? vm.bladeParams.estimatedValue : 0,
					};
				}
			}
		});
	};

	vm.addNewHearing = function () {
		vm.openModal({
			templateUrl: "court_hearing-add",
			controller: 'courtHearingAddCtrl',
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				params: {
					legal_case_id: vm.bladeParams.legalCaseId
				},
				saveCallback: function () {},
			}
		}).then(response => {
			if (!response) return;
			vm.getCourtPhases();
		});
	}

	// used for validations: if action is change_status, don't validate, just change status, else make validations
	vm.prepareDataToSave = function () {
		let postData = angular.copy(vm.data);
		// postData.action = 'change_status';
		return postData;
	};


	return vm;
});
