angular
	.module('kv')
	.directive('lookup', lookup);

/**
 * config object for loookup item template
 * fist level: filter name
 * second level: method name
 */
const lookupOptionsByFilter = {
	//filter name
	external_cui: {
		//method name
		find_company_by_cui: {
			//item has structure: id, text and complete = original item with all fields
			templateResult: function (item) {
				if (!item.id) return item.text;
				const template = $('<div class="filter-custom-template">' +
					'<div class="custom-title">' + item.complete.company_name + '</div>' +
					'<div class="custom-subtitle">' + (item.complete.address ? item.complete.address : '') + '</div> ' +
					'</div>');
				return template;
			}
		}
	},
	contact: {
		//method name
		all_contact: {
			//item has structure: id, text and complete = original item with all fields
			templateResult: function (item) {
				if (!item.id) return item.text;
				const template = $('<div class="filter-custom-template">' +
					'<div class="custom-title">' + item.text + '</div>' +
					'<div class="custom-subtitle">' + (item.complete.customer_name ? item.complete.customer_name : '') + '</div> ' +
					'</div>');
				return template;
			}
		}
	},
	customer_fiscal_entity: {
		all_customer_fiscal_entity: {
			templateResult: function (item) {
				if (!item.id) return item.text;
				const template = $('<div class="all_customer_fiscal_entity">' +
					'<div class="custom-title">' + item.text + '</div>' +
					'<span class="badge badge-sm customer-entity-label ' + item.complete.customer_type_name + ' "  ></span> ' +
					'</div>');
				return template;
			}

		}
	},
	customer: {
		all_customer_with_closed: {
			templateResult: function (item) {
				if (!item.id) return item.text;
				const template = $('<div class="all_customer_fiscal_entity">' +
					'<div class="custom-title">' + item.text + '</div>' +
					(!item.complete.active ? '<span class="badge badge-sm user-badge INACTIVE"  ></span> ' : '') +
					'</div>');
				return template;
			}
		}
	},
	legal_case: {
		all_legal_cases: {
			templateResult: function (item) {
				if (!item.id) return item.text;
				const template = $('<div class="all_customer_fiscal_entity">' +
					'<div class="custom-title">' + item.text + '</div>' +
					(!item.complete.active ? '<span class="badge badge-sm user-badge INACTIVE"  ></span> ' : '') +
					'</div>');
				return template;
			}
		},
	},
	reporting_period: {
		all_reporting_periods: {
			templateResult: function (item) {
				if (!item.id) return item.text;
				const template = $('<div class="filter-custom-template">' +
					'<div class="custom-title">' + item.text + '</div>' +
					'<div class="custom-subtitle">' +
						( item.complete.from ? item.complete.from + ' - ' + item.complete.to : '' ) +
					'</div> ' +
					'</div>');
				return template;
			}
		}
	},
	user: {
		all_users_with_closed: {
			templateResult: function (item) {
				if (!item.id) return item.text;
				const template = $('<div class="all_customer_fiscal_entity">' +
					'<div class="custom-title">' + item.text + '</div>' +
					(!item.complete.active ? '<span class="badge badge-sm user-badge INACTIVE"  ></span> ' : '') +
					'</div>');
				return template;
			}
		},
		all_users_with_security: {
			templateResult: function (item) {
				if (!item.id) return item.text;
				const template = $('<div class="all_customer_fiscal_entity">' +
					'<div class="custom-title">' + item.text + '</div>' +
					(!item.complete.active ? '<span class="badge badge-sm user-badge INACTIVE"  ></span> ' : '') +
					'</div>');
				return template;
			}
		},
		all_report_users: {
			templateResult: function (item) {
				if (!item.id) return item.text;
				const template = $('<div class="all_customer_fiscal_entity">' +
					'<div class="custom-title">' + item.text + '</div>' +
					(!item.complete.active ? '<span class="badge badge-sm user-badge INACTIVE"  ></span> ' : '') +
					'</div>');
				return template;
			}
		},
		all_project_responsibles: {
			templateResult: function (item) {
				if (!item.id) return item.text;
				const template = $('<div class="all_customer_fiscal_entity">' +
					'<div class="custom-title">' + item.text + '</div>' +
					(!item.complete.active ? '<span class="badge badge-sm user-badge INACTIVE"  ></span> ' : '') +
					'</div>');
				return template;
			}
		},
		all_partners_or_controllers: {
			templateResult: function (item) {
				if (!item.id) return item.text;
				const title = item.text;
				const subtitle = item.complete.position || item.complete.master_practice_name || '-';
				return $('<div class="filter-custom-template"><div class="custom-title">' + title + '</div><div class="custom-subtitle">' + subtitle + '</div></div>');
			}
		},
	},
	project: {
		all_project_with_reference: {
			templateResult: function (item) {
				if (!item.id) return item.text;
				const template = $('<div class="all_customer_fiscal_entity">' +
					'<div class="custom-title">' + item.complete.project_name + '<br/>' +
					'<span class="custom-subtitle" style="font-size: 85%">' + item.complete.reference_name + '</span>' +
					'</div>' +
					'<span class="badge badge-sm user-badge badge-info">' + item.complete.project_number + '</span>' +
					'</div>');
				return template;
			}
		},
		all_reference_with_closed: {
			templateResult: function (item) {
				if (!item.id) return item.text;
				const template = $('<div class="all_customer_fiscal_entity">' +
					'<div class="custom-title">' + item.text + '</div>' +
					(!item.complete.enabled ? '<span class="badge badge-sm user-badge INACTIVE"  ></span> ' : '') +
					'</div>');
				return template;
			}
		},
		all_projects_with_closed: {
			templateResult: function (item) {
				if (!item.id) return item.text;
				const template = $('<div class="all_customer_fiscal_entity">' +
					'<div class="custom-title">' + item.text + '</div>' +
					(!item.complete.enabled ? '<span class="badge badge-sm user-badge INACTIVE"  ></span> ' : '') +
					'</div>');
				return template;
			}
		},
		all_project_with_customer: {
			templateResult: function (item) {
				if (!item.id) return item.text;
				const template = $('<div class="all_customer_fiscal_entity">' +
					'<div class="custom-title">' + item.complete.project_name + '<br/>' +
					'<span style="font-size: 85%; font-weight: 500 !important">' + item.complete.customer_name + '</span>' +
					'</div>' +
					// '<div class="custom-subtitle" style="font-weight: 500 !important">' + item.complete.customer_name + '</div> ' +
					'<span class="badge badge-sm user-badge badge-info">' + item.complete.project_number + '</span>' +
					'</div>');
				return template;
			}
		},
	},
	bank_account: {
		all_bank_account: {
			templateResult: function (item) {
				if (!item.id) return item.text;
				const template = $('<div class="all_customer_fiscal_entity">' +
					'<div class="custom-title">' + item.text + '</div>' +
					(!item.complete.active ? '<span class="badge badge-sm user-badge INACTIVE"  ></span> ' : '') +
					'</div>');
				return template;
			}

		}
	},
	budget_item: {
		//method name
		all_budget_item: {
			//item has structure: id, text and complete = original item with all fields
			templateResult: function (item) {
				if (!item.id) return item.text;
				const template = $('<div class="filter-custom-template" style="margin-left:' + parseInt(item.complete.level) * 20 + 'px">' +
					'<div class="custom-title">' + item.text + '</div>' +
					'<div class="custom-subtitle">' +
					(item.complete.comments ? (item.complete.comments.length > 50 ? item.complete.comments.substring(0, 50) + '...' : item.complete.comments) : '') +
					'</div>' +
					'</div>');
				return template;
			}
		}
	},
	invoice: {
		//method name
		all_invoice_detailed: {
			//item has structure: id, text and complete = original item with all fields
			templateResult: function (item) {
				if (!item.id) return item.text;
				const template = $('<div class="filter-custom-template">' +
					'<div class="custom-title">' + item.text + '</div>' +
					'<div class="custom-subtitle">' + (item.complete.projects ? item.complete.projects : '') + '</div> ' +
					'</div>');
				return template;
			}
		}
	}
};

function lookup(API, $filter, $rootScope) {
	return {
		templateUrl: function (elem, attrs) {
			if (typeof attrs.multiple === "undefined") {
				return "/assets/custom/js/directives/lookup/templates/select.html";
			} else {
				return "/assets/custom/js/directives/lookup/templates/multiple.html";
			}
		},
		scope: {
			ngModel: '=',
			default: '=?',
			options: '=?',
			filter: '@',
			method: '@',
			related: '=?',
			placeholder: '@',
			onChange: '&',
			ngDisabled: '&?',
			name: '@',
			onChangeUnset: '@?',
			allowClear: '=?',
			minimumInputLength: '=?',
			delay: '=?'
		},
		transclude: true,
		compile: function () {
			// var listener;
			return {
				pre: function (scope, element, attrs, controller) {
					var listener;
					scope.isChanging = false;
					if (typeof scope.placeholder === "undefined") {
						scope.placeholder = "Select an option";
					}

					try {
						if (!attrs.default) {
							var parts = attrs.ngModel.split(".");
							if (parts.length) {
								var identifier = parts[parts.length - 1];
								if (scope.$root._search && scope.$root._search[identifier + "_default"]) {
									scope.default = scope.$root._search[identifier + "_default"];
								}
							}
						}
					} catch (err) {
						console.log(err);
					}

					if (attrs.required) {
						element.find('select').attr('required', true);
					}

					// setTimeout(function () {
					// 	element.find('.select2').width("100%");
					// });

					scope.for = attrs.ngModel;

					if (typeof attrs.multiple !== "undefined") {
						scope.allowClear = false;
					}

					scope.select2Options = {
						minimumInputLength: (typeof attrs.minimumInputLength === 'undefined') ? 0 : attrs.minimumInputLength,
						allowClear: (typeof scope.allowClear === 'undefined') ? true : scope.allowClear,
						placeholder: scope.placeholder,
						ajax: {
							url: function () {
								var method = angular.copy(scope.method);
								if (scope.related) {
									method += jsonToQueryString(scope.related);
								}
								return API.URL + 'filter/' + scope.filter + '/' + method;
							},
							dataType: 'json',
							delay: (typeof attrs.delay === 'undefined') ? 0 : attrs.delay,
							data: function (params) {
								return {
									q: escapeRegExp(params.term), // search term
									page: params.page
								};
							},
							processResults: function (res, params) {
								let data = res.data ? res.data : res;
								params.page = params.page || 1;

								return {
									results: data.map(function (item) {
										// if value is 0 we transform it to string
									    // https://github.com/select2/select2/issues/2862
										if (item.id === 0) {
											item.id = item.id.toString();
										}
										return {
											id: item.id,
											text: attrs.trans === 'false' ? (item.name) : $filter('trans')(item.name),
											complete: item
										};
									}),
									pagination: {
										more: params.page < res.last_page
									}
								};
							},
							cache: true
						},
						initSelection: function (element, callback) {
							listener = scope.$watchCollection("[ngModel,isChanging]", function (value) {
								if (!scope.isChanging) {
									// if(value && scope.default) {
									if (scope.ngModel && scope.default && (!scope.entities || (scope.entities && scope.entities.id !== scope.ngModel))) {
										scope.entities = [
											{
												"id": scope.ngModel,
												"text": attrs.trans === 'false' ? (scope.default) : $filter('trans')(scope.default)
											}
										];
										element.find('select').val(scope.ngModel).trigger('change');
										scope.selected = scope.entities[0];
									}

									if (scope.ngModel && typeof scope.ngModel === "object") {
										scope.entities = scope.ngModel;
										scope.selected = scope.entities.map(function (item) {
											return {
												id: item.id,
												text: attrs.trans === 'false' ? (item.name) : $filter('trans')(item.name),
												name: attrs.trans === 'false' ? (item.name) : $filter('trans')(item.name)
											};
										});
										scope.entities = scope.selected;
										listener();
									}
									if (scope.selected) {
										element.attr("data-value", scope.selected.id);
										if (scope.ngModel) {
											callback(scope.selected);
										} else {
											callback({id: "", "text": ""});
										}
									} else {
										callback({id: "", "text": ""});
									}
								}
							});
						}
					};
					if (attrs.options) {
						scope.select2Options = angular.extend(scope.select2Options, scope.options);
					} else if (lookupOptionsByFilter[attrs.filter] && lookupOptionsByFilter[attrs.filter][attrs.method])
						scope.select2Options = angular.extend(scope.select2Options, lookupOptionsByFilter[attrs.filter][attrs.method]);

					element.find('select').select2(scope.select2Options);

					element.find('select').on('change', function (evt) {
						scope.isChanging = true;
						if (typeof attrs.multiple !== "undefined") {
							listener();
						}

						var data = element.find("select").select2('data');

						try {
							if (!$rootScope["lookup"]) {
								$rootScope["lookup"] = {};
							}
							if (!$rootScope["lookup"][scope.filter]) {
								$rootScope["lookup"][scope.filter] = {};
							}
							$rootScope["lookup"][scope.filter][scope.method] = data[0]["complete"];
							$rootScope["lookup"][attrs.ngModel] = data[0]["complete"];
						} catch (err) {

						}
						if (typeof attrs.multiple === "undefined") {
							if (data.length && data[0].id) {
								scope.ngModel = data[0].id;
							} else {
								scope.ngModel = null;
							}
							scope.$apply(scope.ngModel);
							try {
								if (attrs.ngModel !== attrs.default) {
									scope.default = data[0].text;
									scope.$apply();
								}
							} catch (e) {
							}
						}

						if (typeof attrs.multiple !== "undefined") {
							if (data.length) {
								scope.ngModel = data.map(function (item) {
									return {
										id: item.id,
										name: attrs.trans === 'false' ? (item.text) : $filter('trans')(item.text)
									};
								});
							} else {
								scope.ngModel = null;
							}
							scope.$apply(scope.ngModel);
						}

						if (data === null) {
							scope.ngModel = null;
						}

						if (element.find('.select2 ').length) {
							element.find('select').on("select2:close", function () {
								angular.element(this).focus();
							});
						}
						if (scope.onChangeUnset) {
							unsetLookups();
						}
						scope.isChanging = false;
						scope.onChange();
					});

					function unsetLookups() {
						angular.forEach(scope.onChangeUnset.split(","), function (v) {
							angular.element('lookup[ng-model="' + v.trim() + '"] select').val(null);
							angular.element('textarea[ng-model="' + v.trim() + '"]').val(null);
							angular.element('input[ng-model="' + v.trim() + '"]').val(null);

							setTimeout(function () {
								angular.element('lookup[ng-model="' + v.trim() + '"] select').trigger('change');
								angular.element('textarea[ng-model="' + v.trim() + '"]').trigger('change');
								angular.element('input[ng-model="' + v.trim() + '"]').trigger('change');
							}, 200);
						});
					}
				}, post: function (scope, element, attrs, controller, transclude) {
					if (transclude().length) {
						element.find('.content').show().append(transclude()).removeClass('content');
					}
				}
			};
		}
	};
}

function jsonToQueryString(json) {
	return '?' +
		Object.keys(json).map(function (key) {
			return encodeURIComponent(key) + '=' + ((json[key]) ? encodeURIComponent(json[key]) : "");
		}).join('&');
}

function escapeRegExp(text) {
	if(typeof text !== 'undefined'){
		return text.replace(/'/g, "\\'");
	}
}

