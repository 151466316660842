kv.controller('tenantFiscalEntityEditCtrl', function ($uibModalInstance, params, saveCallback, $scope, $injector, resource, $http) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'tenant_fiscal_entity', 'tenant_fiscal_entity_id', $scope, params);

	var eFacturaREST = resource.init('efactura');

	vm.filesUpload = {
		loaded: true,
		loaderSelector: "#logo-image",
		uploaded: function (response, loader) {
			if (response && response.status) vm.data.logo_path = response.data + "?v=" + Math.random();
			else vm.data.logo_path = "";
			if (loader) loader.remove();
		}
	};

	if (!params.tenant_fiscal_entity_id) {
		vm.data = params;
		vm.isNewRecord = true;
		vm.data.is_default = 0;
		vm.data.pay_vat_on_invoice = 0;
		vm.data.display_logo = 0;
		vm.data.display_annex_subtotals = 0;
		vm.data.show_due_invoices_on_annex = 0;
	} else {
		vm.modal.rendered.then(() => {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
		});
		let rest = vm.getInjection('resource').init('tenant_fiscal_entity');
		rest.get({id: params.tenant_fiscal_entity_id, query: {with_settings: true}}).then((response) => {
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
			if (response.$resolved) vm.data = response;
			else console.error(response);
		});
	}

	$scope.$watch('[vm.data.send_invoices_automatically_to_anaf]', function (value) {
		if (parseInt(value) == 0) { // intern
			vm.data.send_invoice_to_anaf_days = 0;
		}
	});

	$scope.$watch('[vm.data.anaf_city]', function (value) {
		if (parseInt(value) == 5) { // intern
			vm.data.currency = API.user.tenant.reporting_currency;
		}
	});

	$scope.$watch('[vm.data.country_id]', function (value) {
		if (parseInt(value) != 85) {
			vm.data.region_id = '';
			vm.data.sector = '';
		}
	});

	vm.saveData = function () {
		return vm.save();
	};
	vm.saveCallback = saveCallback;

	vm.refreshTokenEFactura = function (tenant_fiscal_entity_id) {
		try {
			vm.confirm('confirm', vm.trans('LANG.CONFIRM_REFRESH_TOKEN')).then((response) => {
				if (response !== "ok") return;
				KApp.block('.modal-content');
				eFacturaREST.post({
					url: 'efactura/refresh/',
					data: {
						tenant_fiscal_entity_id: tenant_fiscal_entity_id,
					}
				})
				.then((response) => {
					if (response.original.status === true) {
						//vm.data.efactura_token = response.data.efactura_token;
						vm.data.efactura_token_expiration = response.original.data;
						KApp.unblock('.modal-content');
						toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
						vm.close();
					}
				});
			});

		} catch (error) {
			console.error(error);
		}
	};

	vm.accountant = {
		loading: false,
		link: null,
		error: null,
	};

	vm.generateLink = (route) => {
		const url = API.URL + 'efactura/generate-link?oauth_type=jwt&tenant_fiscal_entity_id=' + vm.data.tenant_fiscal_entity_id;
		vm.accountant.loading = true;
		try {
			eFacturaREST.get({
				url: url,
			})
			.then((response) => {
				if (response.status) {
					vm.accountant.link = response.link;
					vm.accountant.loading = false;
				}
			});

		} catch (error) {
			console.error(error);
			vm.accountant.loading = false;
			vm.accountant.error = error;
		}
	};

	vm.copyLink = () => {
	//	navigator.clipboard.writeText(vm.accountant.link);

		var tempTextarea = document.createElement('textarea');
		tempTextarea.value = vm.accountant.link;
		document.body.appendChild(tempTextarea);
		tempTextarea.select();

		document.execCommand('copy');
		document.body.removeChild(tempTextarea); // Clean up the DOM
	};

	vm.sendEmailToAccountant = (accountantEmail, accountantLink, tenantFiscalEntityId) => {
		if(accountantEmail != undefined) {
			try {
				KApp.block('.modal-content');
				eFacturaREST.post({
					url: 'efactura/send_email_to_accountant',
					data: {
						accountant_email: accountantEmail,
						accountant_link: accountantLink,
						tenant_fiscal_entity_id: tenantFiscalEntityId,
					}
				})
					.then((response) => {
						KApp.unblock('.modal-content');
						if (response.status) {
							toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
						} else {
							toastr.error(vm.trans('ACTION_REJECTED'));
						}
					});
			} catch (error) {
				console.error(error);
			}
		} else {
			toastr.error(vm.trans('LANG.ACCOUNTANT_EMAIL_VALIDATION'));
		}

	};

	return vm;
});
